import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import PlacePreview from '../components/places/preview/place-preview'
import ItemPreview from '../components/itemPreview/item-preview'

class NeighborhoodTemplate extends React.Component {
  render() {
    const neighborhood = get(this.props, 'data.contentfulNeighborhood')
    const places = get(this, 'props.data.allContentfulPlace.nodes')


    return (
      <Layout location={this.props.location}>
        <Seo
          title={neighborhood.name}
          description={''}
          image={''}
        />
        
          <>
            <div>NEIGHBORHOOD</div>
            <h1>{ neighborhood.name }</h1>
          </>

          {/* <PlacePreview places={places} /> */}

          <hr/>
          <h2>PLACES</h2>
          <ItemPreview items={places} slugName="place" />


          

          
         
      </Layout>
    )
  }
}

export default NeighborhoodTemplate

export const pageQuery = graphql`
  query NeighborhoodBySlug(
    $slug: String!
    $neighborhood: String!
  ) {
    contentfulNeighborhood(slug: { eq: $slug }) {
        name
        slug
        cities {
            name
        }
    }
    allContentfulPlace(filter: {neighborhood: {name: {eq: $neighborhood}}}) {
      nodes {
        name
        slug
        neighborhood {
          name
        }
      }
    }
  }
`
